import Navbar from "./components/Navbar";
import "./app.css";
import Home from "./pages/Home";
import Post from "./pages/Post";
import Login from "./pages/Login";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

// const API_URL='http://localhost:5000'
const API_URL = "https://api-react-social-login.tividad.com";
const App = () => {
  const [user, setUser] = useState(null);

  // const getUser = () => {
  //   // fetch(`${API_URL}/auth/login/success`, {
  //   // await fetch("https://api-react-social-login.tividad.com/auth/login/success", {
  //   //   method: "GET",
  //   //   credentials: "include",
  //   //   headers: {
  //   //     Accept: "application/json",
  //   //     "Content-Type": "application/json",
  //   //     "Access-Control-Allow-Credentials": true,
  //   //   },
  //   // })
  //   // https://api-react-social-login.tividad.com/auth/login/success
  //   axios
  //     .get(`${API_URL}/auth/login/success`)
  //     .then((response) => {
  //       console.log("response >>>>", response);
  //       if (response.status === 200) return response.json();
  //       throw new Error("authentication has been failed!");
  //     })
  //     .then((resObject) => {
  //       console.log("resObject >>>>", resObject);
  //       setUser(resObject.user);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // useEffect(() => {
  //   // alert("https://api-react-social-login.tividad.com/auth/login/success")
  //   getUser();
  // }, []);
  useEffect(() => {
    const getUser = () => {
      // fetch("http://localhost:5000/auth/login/success", {
      fetch(`${API_URL}/auth/login/success`, {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": true,
        },
      })
        .then((response) => {
          console.log('response >>>', response);
          if (response.status === 200) return response.json();
          throw new Error("authentication has been failed!");
        })
        .then((resObject) => {
          console.log('resObject.user >>>', resObject.user)
          setUser(resObject.user);
        })
        .catch((err) => {
          console.log('err >>>', err)
        });
    };
    getUser();
  }, []);

  return (
    <BrowserRouter>
      <div>
        <Navbar user={user} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/login"
            element={user ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="/post/:id"
            element={user ? <Post /> : <Navigate to="/login" />}
          />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
